import React from 'react'
import Hand from "../assets/images/hand.png";
import Setup from "../assets/images/setup.jpg";
import { FaInstagram } from "react-icons/fa";
import { LiaLinkedin } from "react-icons/lia";
import { TbMail } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";

const Home = () => {
  return (
    <div id='home' className="flex flex-col lg:flex-row  w-full h-full md:h-screen  items-center justify-between pb-10 md:pb-0">
        <div className="w-1/6 justify-center pt-32 md:mt-0 gap-4 flex flex-row lg:flex-col pb-10 lg:pb-0 px-4 md:px-0">
            <a href='https://www.instagram.com/frnd.eva/' target='_blank'><FaInstagram className="text-4xl cursor-pointer"/></a>
            <a href='https://www.linkedin.com/in/eva-ferrand-2694b51b7/' target='_blank'><LiaLinkedin className="text-4xl cursor-pointer"/></a>
            <a href='mailto:contact@evaferrand.fr'><TbMail className="text-4xl cursor-pointer"/></a>
        </div>

        <div className="flex flex-col text-center lg:text-left">
            <div className="flex items-center gap-4 mb-4 justify-center lg:justify-start">
                <h2 className="text-4xl md:text-5xl text-black-primary font-semibold dark:text-white-primary">Eva Ferrand</h2>
                <img src={Hand} alt="Main" className=""/>
            </div>
            <h1 className="text-primary text-4xl md:text-6xl mb-4">Web Conceptrice</h1>
            <p className="mb-4 pr-0 lg:pr-40 mx-4 md:mx-24 lg:mx-0">
            <span className="block">
                Formation Concepteur Web en cours...
            </span>
            Je suis passionnée par la programmation informatique depuis 5 ans. 
            Je suis quelqu'un de très patiente et motivée pour mener à bien vos projets.
            </p>
            <div className="flex items-center justify-center lg:justify-start">
                <div className="flex items-center gap-2 cursor-pointer border-2 border-black-secondary rounded-full py-1 px-4 hover:bg-black-primary hover:text-white-primary hover:border-black-primary dark:border-white-primary dark:hover:bg-white-primary dark:hover:text-black-primary">
                    <FaPhoneAlt className="text-1xl" />
                    <p>06 26 69 55 70</p>
                </div>
            </div>
        </div>

        <div className="w-2/3 lg:w-2/3 mt-10 md:mt-0">
            <img src={Setup} alt="" className="setup"/>
        </div>    
    </div>
  )
}

export default Home