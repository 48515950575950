import React from 'react'
import Pictogramme from "../assets/images/pictogramme.svg"
import { MdOutlineFileDownload } from "react-icons/md";
import PdfCahierDesCharge from './CahierDesCharges.pdf';

const CahierDesCharge = () => {
    
  return (
    <div name="Cahier" className="flex flex-col-reverse md:flex-row items-center mt-20 px-4 md:px-0">
                <div className="w-2/3 md:w-1/3">
                    <img src={Pictogramme} alt="" className=""/>
                </div>

                <div className="w-full md:w-2/3 pl-0 md:pl-20 text-center md:text-left">
                    <h3 className="text-2xl md:text-3xl pb-10">Vous savez déjà ce qu'il vous faut ?</h3>
                    <p className="pb-5">Si vous souhaitez obtenir un devis, vous devez remplir le cahier des charges avec le maximum d'informations. Merci de prendre le temps de le remplir correctement, car qui mieux que vous peut parler de votre entreprise.</p>
                    <p className="pb-10">On peut également le remplir ensemble lors d'un premier rendez-vous.</p>
                    <a href={PdfCahierDesCharge} download className="flex items-center w-60 mx-auto md:mx-0 mb-10 md:mb-0 gap-4 border-2 border-black-secondary rounded-full py-1 px-4 hover:bg-black-primary hover:text-white-primary hover:border-black-primary dark:border-white-primary dark:hover:bg-white-primary dark:hover:text-black-primary">
                        Cahier des charges
                        <MdOutlineFileDownload className="text-2xl"/>
                    </a>
                </div>
            </div>
  )
}

export default CahierDesCharge