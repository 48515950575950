import React from 'react'

import Site from "../../assets/images/projects/Ekod1/ekod1.jpeg";
import Maquette from "../../assets/images/projects/Ekod1/maquetteEkod1.jpeg";

const Ekod1 = () => {
  return (
    <div className="pt-36">
        {/* Lien vers le site */}
        <div className='flex items-center justify-center'>
            <a href='https://www.ekodcms.com/FERRAND-Eva/' target='_blank' className='cursor-pointer border-2 border-black-secondary rounded-full mb-4 py-1 px-4 hover:bg-black-primary hover:text-white-primary hover:border-black-primary dark:border-white-primary dark:hover:bg-white-primary dark:hover:text-black-primary'>Voir le site</a>
        </div>
        
        {/* Présentation */}
        <div className="mt-10">
            <h3 className="font-secondary text-4xl">Projet Ekod</h3>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <div className="flex items-center mt-6">
                <div className="w-1/2">
                    <div className="flex flex-col md:flex-row items-left md:items-center justify-between">
                        <h4 className="font-secondary text-2xl">Categorie</h4>
                        <p className="mr-32">Site vitrine</p>
                    </div>
                    <div className="flex flex-col md:flex-row items-left md:items-center justify-between">
                        <h4 className="font-secondary text-2xl">Annee</h4>
                        <p className="mr-32">2024</p>
                    </div>
                </div>
                <div className="w-1/2">
                    <p>My Site a été réalisé lors d'un contrôle avec ma formation.</p>
                </div>
            </div>
        </div>

        {/* Site */}
        <div className=" mt-10 px-10 pt-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="font-secondary text-2xl"><span className="font-primary text-sm">01.</span>Maquette Popularfx</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <img src={Maquette} alt="" className="flex items-center justify-center mx-auto py-10"/>
        </div>

        {/* Site Mobile */}
        <div className=" mt-10 px-10 pt-10  bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className=" font-secondary text-2xl"><span className="font-primary text-sm">02.</span>Site Web</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <img src={Site} alt="" className="flex items-center justify-center mx-auto py-10"/>
        </div>
    </div>
  )
}

export default Ekod1