import React from 'react'
import Figma from "../assets/images/figma.svg";
import Adobe from "../assets/images/adobe.svg";
import Html from "../assets/images/html.svg";
import Css from "../assets/images/css.svg";
import Js from "../assets/images/js.svg";
import Php from "../assets/images/php.svg";
import REACT from "../assets/images/react.svg";
import Wordpress from "../assets/images/wordpress.svg";

const Skills = () => {
  return (
    <div id='competences' className=" w-full h-full md:h-screen">
        <div className='flex flex-col items-center justify-center w-full h-full mt-20 md:mt-0'>
            <h2 className="text-2xl lg:text-4xl mb-10 text-center">-Mes compétences-</h2> 

            <div className='mt-4 grid grid-cols-2 md:grid-cols-4 gap-x-8 gap-y-12 lg:gap-x-16 lg:gap-y-20'>
                    <div className="w-20 h-20 md:w-24 md:h-24 lg:w-32 lg:h-32 hover:scale-110 duration-500">
                        <div className="w-full h-full text-center flex flex-col items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full ">
                            <img src={Figma} alt="" className="w-2/4"/>
                        </div>
                        <p className="text-center mt-2 text-1xl md:text-2xl">Figma</p>
                    </div>

                    <div className="w-20 h-20 lg:w-32 lg:h-32 hover:scale-110 duration-500">
                        <div className="w-full h-full text-center flex flex-col items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full ">
                            <img src={Adobe} alt="" className="w-2/4"/>
                        </div>
                        <p className="text-center mt-2 text-1xl md:text-2xl">Adobe</p>
                    </div>

                    <div className="w-20 h-20 lg:w-32 lg:h-32 hover:scale-110 duration-500">
                        <div className="w-full h-full text-center flex flex-col items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full ">
                            <img src={Html} alt="" className="w-2/4"/>
                        </div>
                        <p className="text-center mt-2 text-1xl md:text-2xl">HTML</p>
                    </div>

                    <div className="w-20 h-20 lg:w-32 lg:h-32 hover:scale-110 duration-500">
                        <div className="w-full h-full text-center flex flex-col items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full ">
                            <img src={Css} alt="" className="w-2/4"/>
                        </div>
                        <p className="text-center mt-2 text-1xl md:text-2xl">CSS</p>
                    </div>

                    <div className="w-20 h-20 lg:w-32 lg:h-32 hover:scale-110 duration-500">
                        <div className="w-full h-full text-center flex flex-col items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full ">
                            <img src={Js} alt="" className="w-2/4"/>
                        </div>
                        <p className="text-center mt-2 text-1xl md:text-2xl">JavaScript</p>
                    </div>

                    <div className="w-20 h-20 lg:w-32 lg:h-32 hover:scale-110 duration-500">
                        <div className="w-full h-full text-center flex flex-col items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full ">
                            <img src={Php} alt="" className="w-2/4"/>
                        </div>
                        <p className="text-center mt-2 text-1xl md:text-2xl">PHP</p>
                    </div>

                    <div className="w-20 h-20 lg:w-32 lg:h-32 hover:scale-110 duration-500">
                        <div className="w-full h-full text-center flex flex-col items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full ">
                            <img src={REACT} alt="" className="w-2/4"/>
                        </div>
                        <p className="text-center mt-2 text-1xl md:text-2xl">React</p>
                    </div>

                    <div className="w-20 h-20 lg:w-32 lg:h-32 hover:scale-110 duration-500">
                        <div className="w-full h-full text-center flex flex-col items-center justify-center bg-gradient-to-b from-secondary to-primary rounded-full ">
                            <img src={Wordpress} alt="" className="w-2/4"/>
                        </div>
                        <p className="text-center mt-2 text-1xl md:text-2xl">Wordpress</p>
                    </div>
                </div>
               </div>
            </div>
  )
}

export default Skills