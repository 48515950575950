import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import ImgSolutionCaisse from "../assets/images/projects/SolutionCaisse/solutionCaisse.png";
import ImgEkod1 from "../assets/images/projects/Ekod1/wordpressEkod1.png";
import ImgGarageFerrand from "../assets/images/projects/GarageFerrand/garageferrand.png";
import ImgWordpress1 from "../assets/images/projects/Wordpress1/wordpress3.png";
import ImgEkod2 from '../assets/images/projects/Ekod2/ProjetEkod2.png';

const projectsData = [
    { id: 1, title: 'SolutionCaisse', category : 'UX/UI', imageUrl: ImgSolutionCaisse, lien: "/solutionCaisse" },
    { id: 2, title: 'Ekod1', category : 'wordpress', imageUrl: ImgEkod1, lien: "/Ekod1" },
    { id: 3, title: 'GarageFerrand', category : 'HTML/CSS', imageUrl: ImgGarageFerrand, lien: "/GarageFerrand" },
    { id: 4, title: 'Wordpress1', category : 'wordpress', imageUrl: ImgWordpress1, lien: "/Wordpress1" },
    { id: 5, title: 'Ekod2', category : 'wordpress', imageUrl: ImgEkod2, lien: "/Ekod2" },
];

const Projects = () => {
  const [category, setCategory] = useState("Tout");
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setStartIndex(0); // Réinitialise l'indice de départ lorsque la catégorie est modifiée
    setCurrentPage(0); // Réinitialise l'indice de la page lorsque la catégorie est modifiée
  }, [category]);

  const filteredProjects = projectsData.filter(project => {
    return category === "Tout" || project.category === category;
  });

  const handleClickNext = () => {
    const visibleProjectsCount = getVisibleProjectsCount();
    const maxIndex = filteredProjects.length - visibleProjectsCount;
    setStartIndex(prevIndex => prevIndex + visibleProjectsCount >= filteredProjects.length ? 0 : Math.min(prevIndex + visibleProjectsCount, maxIndex));
    setCurrentPage(prevPage => (prevPage + 1) % Math.ceil(filteredProjects.length / visibleProjectsCount)); // Met à jour l'indice de la page
  };

  const handleClickPrev = () => {
    const visibleProjectsCount = getVisibleProjectsCount();
    const maxIndex = filteredProjects.length - visibleProjectsCount;
    setStartIndex(prevIndex => prevIndex === 0 ? maxIndex : Math.max(prevIndex - visibleProjectsCount, 0));
    setCurrentPage(prevPage => prevPage === 0 ? Math.ceil(filteredProjects.length / visibleProjectsCount) - 1 : prevPage - 1); // Met à jour l'indice de la page
  };

  const handlePageClick = (pageIndex) => {
    const visibleProjectsCount = getVisibleProjectsCount();
    setStartIndex(pageIndex * visibleProjectsCount);
    setCurrentPage(pageIndex);
  };

  const getVisibleProjectsCount = () => {
    // Détermine le nombre de projets visibles en fonction de la largeur de l'écran
    if (window.innerWidth <= 768) {
      return 1; // Affiche un seul projet sur les petits écrans (mobiles)
    } else {
      return 2; // Affiche deux projets sur les écrans plus larges (tablettes et ordinateurs)
    }
  };

  return (
    <div id="projets" className="w-full h-full md:h-screen">
    <div className='flex flex-col items-center justify-center w-full h-full mt-20 md:mt-0 px-4 md:px-0'>
      <h2 className="text-2xl lg:text-4xl mb-10 text-center">-Mes projets-</h2>
         
      <div className="flex gap-10 items-center justify-center mb-10">
        <div className="border-2 dark:border-black-secondary rounded-full">
            <button className={`${category === 'Tout' ? 'bg-gradient-to-b from-secondary to-primary' : ''} px-2 md:px-4 py-2 rounded-full`} onClick={() => setCategory("Tout")}>Tout</button>
            <button className={`${category === 'UX/UI' ? 'bg-gradient-to-b from-secondary to-primary' : ''} px-2 md:px-4 py-2 rounded-full`} onClick={() => setCategory("UX/UI")}>UX/UI</button>
            <button className={`${category === 'wordpress' ? 'bg-gradient-to-b from-secondary to-primary' : ''} px-2 md:px-4 py-2 rounded-full`} onClick={() => setCategory("wordpress")}>Wordpress</button>
            <button className={`${category === 'HTML/CSS' ? 'bg-gradient-to-b from-secondary to-primary' : ''} px-2 md:px-4 py-2 rounded-full`} onClick={() => setCategory("HTML/CSS")}>HTML/CSS</button>
        </div>
      </div>

      <div className='flex'>
        <button onClick={handleClickPrev}><IoIosArrowBack className='text-4xl'/></button>
        {filteredProjects.slice(startIndex, startIndex + getVisibleProjectsCount()).map(project => (
          <div key={project.id} style={{ margin: "0 20px" }}>
            <a href={project.lien} rel="noopener noreferrer" className="project-card">
                
                <img src={project.imageUrl} alt={project.title} className="w-72 rounded-xl z-10" />
           
             </a>
          </div>
        ))}
        <button onClick={handleClickNext}><IoIosArrowForward className='text-4xl'/></button>
      </div>

      <div className="flex justify-center mt-5">
          {Array.from({ length: Math.ceil(filteredProjects.length / getVisibleProjectsCount()) }, (_, index) => (
            <button
              key={index}
              className={`mx-2 w-4 h-4 rounded-full ${index === currentPage ? 'bg-primary dark:bg-secondary' : 'bg-gray-300'}`}
              onClick={() => handlePageClick(index)}
            />
          ))}
      </div>
    </div>
    </div>
  );
};

export default Projects;