import React from 'react'

import Logo from "../../assets/images/projects/GarageFerrand/logo.svg";
import Site from "../../assets/images/projects/GarageFerrand/site.jpeg";
import SiteMobile from "../../assets/images/projects/GarageFerrand/siteMobile.png";


const GarageFerrand = () => {
  return (
    <div className="pt-36">
        {/* Lien vers le site */}
        <div className='flex items-center justify-center'>
            <a href='https://garageferrand.fr' className='cursor-pointer border-2 border-black-secondary rounded-full mb-4 py-1 px-4 hover:bg-black-primary hover:text-white-primary hover:border-black-primary dark:border-white-primary dark:hover:bg-white-primary dark:hover:text-black-primary'>Voir le site</a>
        </div>
        

        {/* Logo */}
        <div className="flex flex-col items-center justify-center bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <img src={Logo} alt="Logo SolutionCaisse" className=" py-10"/>
        </div>

        {/* Présentation */}
        <div className="mt-10">
            <h3 className="font-secondary text-4xl">Garage Ferrand</h3>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <div className="flex flex-col md:flex-row items-center mt-6">
                <div className="w-full md:w-1/2">
                    <div className="flex flex-col md:flex-row items-left md:items-center justify-between text-center md:text-left pb-4 md:pb-0">
                        <h4 className="font-secondary text-2xl">Categorie</h4>
                        <p className="mr-0 md:mr-32">Site vitrine</p>
                    </div>
                    <div className="flex flex-col md:flex-row items-left md:items-center justify-between text-center md:text-left pb-4 md:pb-0">
                        <h4 className="font-secondary text-2xl">Annee</h4>
                        <p className="mr-0 md:mr-32">2024</p>
                    </div>
                </div>
                <div className="w-full md:w-1/2 text-center md:text-left">
                    <p>Le garage Ferrand est bien plus qu'un simple lieu de réparation automobile. Spécialisé dans le dépannage autoroute, l'entretien de véhicules toutes marques et le diagnostic automobile, il offre une gamme complète de services pour répondre aux besoins variés des conducteurs.</p>
                </div>
            </div>
        </div>

        <h3 className="font-secondary text-4xl text-center mt-20 md:mt-28">-Charte Graphique-</h3>

        {/* Palette de couleurs */}
        <div className="h-full mt-10 px-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="pt-10  font-secondary text-2xl"><span className="font-primary text-sm">01.</span>Palette de couleurs</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>

            <div className="flex items-center justify-center mx-auto w-full md:w-3/4 py-20 rounded-full text-white-primary">
                <div className="flex items-center justify-center w-1/4 h-64 bg-[#003253] rounded-l-full text-[14px] md:text-2xl">#003253</div>
                <div className="flex items-center justify-center w-1/4 h-64 bg-[#4C90CD] text-[14px] md:text-2xl">#4C90CD</div>
                <div className="flex items-center justify-center w-1/4 h-64 bg-[#000] text-[14px] md:text-2xl">#000000</div>
                <div className="flex items-center justify-center w-1/4 h-64 bg-[#FFF] rounded-r-full text-black-primary text-[14px] md:text-2xl">#FFFFFF</div>
            </div>
        </div>

        {/* Palette de couleurs */}
        <div className=" mt-10 px-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="pt-10  font-secondary text-2xl"><span className="font-primary text-sm">02.</span>Typographie</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>

            <div className="flex flex-col md:flex-row items-center text-center md:justify-evenly py-20">
                <div className="mb-4 md:mb-0">
                    <p>Titre : Poppins</p>
                    <p className="font-primary font-medium">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
                    <p className="font-primary font-medium">abcdefghijklmnopqrstuvwxyz</p>
                    <p className="font-primary font-medium">1234567890</p>
                </div>
                <div className="">
                    <p>Paragraphes : Open Sans</p>
                    <p className="font-garage-ferrand">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
                    <p className="font-garage-ferrand">abcdefghijklmnopqrstuvwxyz</p>
                    <p className="font-garage-ferrand">1234567890</p>
                </div>
            </div>
        </div>

        {/* Site */}
        <div className=" mt-10 px-10 pt-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="font-secondary text-2xl"><span className="font-primary text-sm">03.</span>Site Web - Ordinateur</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <img src={Site} alt="" className="flex items-center justify-center mx-auto py-10"/>
        </div>

        {/* Site Mobile */}
        <div className=" mt-10 px-10 pt-10  bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className=" font-secondary text-2xl"><span className="font-primary text-sm">03.</span>Site Web - Mobile</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <img src={SiteMobile} alt="" className="flex items-center justify-center mx-auto w-64 py-10"/>
        </div>
    </div>
  )
}

export default GarageFerrand