import React from 'react'

import Logo from "../../assets/images/projects/SolutionCaisse/logo.png";
import Zoning from "../../assets/images/projects/SolutionCaisse/zoning.png";
import Maquette from "../../assets/images/projects/SolutionCaisse/maquette.png";

const SolutionCaisse = () => {
  return (
    <div className="pt-36">
        {/* Logo */}
        <div className="flex flex-col items-center justify-center bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <img src={Logo} alt="Logo SolutionCaisse" className="w-1/3 py-10"/>
        </div>

        {/* Présentation */}
        <div className="mt-10">
            <h3 className="font-secondary text-4xl">Solution Caisse</h3>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <div className="flex flex-col md:flex-row items-center mt-6">
                <div className="w-full md:w-1/2 ">
                    <div className="flex flex-col md:flex-row items-left md:items-center justify-between text-center md:text-left pb-4 md:pb-0">
                        <h4 className="font-secondary text-2xl">Categorie</h4>
                        <p className="mr-0 md:mr-32">Refonte | Site vitrine</p>
                    </div>
                    <div className="w-full md:flex flex-col md:flex-row items-left md:items-center justify-between text-center md:text-left pb-4 md:pb-0">
                        <h4 className="font-secondary text-2xl">Annee</h4>
                        <p className="mr-0 md:mr-32">2024</p>
                    </div>
                </div>
                <div className="w-full md:w-1/2 text-center md:text-left">
                    <p>Solution Caisse est une entreprise située à la Flèche (72200) et est depuis 15 ans au service des commerçants. Ils proposent des solutions d'encaissement, de pesage et toutes autres solutions de point de vente adaptées et performantes.</p>
                </div>
            </div>
        </div>

        <h3 className="font-secondary text-4xl text-center mt-20 md:mt-28">-Charte Graphique-</h3>

        {/* Palette de couleurs */}
        <div className="h-full mt-10 px-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="pt-10  font-secondary text-2xl"><span className="font-primary text-sm">01.</span>Palette de couleurs</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>

            <div className="flex items-center justify-center mx-auto w-full md:w-3/4 py-20 rounded-full text-white-primary">
                <div className="flex items-center justify-center w-1/4 h-64 bg-[#A3195B] rounded-l-full text-[14px] md:text-2xl">#A3195B</div>
                <div className="flex items-center justify-center w-1/4 h-64 bg-[#3C3C3B] text-[14px] md:text-2xl">#3C3C3B</div>
                <div className="flex items-center justify-center w-1/4 h-64 bg-[#1D1D1B] text-[14px] md:text-2xl">#1D1D1B</div>
                <div className="flex items-center justify-center w-1/4 h-64 bg-[#F1F1F1] rounded-r-full text-black-primary text-[14px] md:text-2xl" >#F1F1F1</div>
            </div>
        </div>

        {/* Typographies */}
        <div className=" mt-10 px-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="pt-10  font-secondary text-2xl"><span className="font-primary text-sm">02.</span>Typographie</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>

            <div className="flex flex-col md:flex-row items-center text-center md:justify-evenly py-20">
                <div className="mb-4 md:mb-0">
                    <p>Titre : Roboto Condensed</p>
                    <p className="font-solution-caisse-secondary font-medium">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
                    <p className="font-solution-caisse-secondary font-medium">abcdefghijklmnopqrstuvwxyz</p>
                    <p className="font-solution-caisse-secondary font-medium">1234567890</p>
                </div>
                <div className="">
                    <p>Paragraphes : Roboto</p>
                    <p className="font-solution-caisse-primary">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
                    <p className="font-solution-caisse-primary">abcdefghijklmnopqrstuvwxyz</p>
                    <p className="font-solution-caisse-primary">1234567890</p>
                </div>
            </div>
        </div>

        {/* Zoning */}
        <div className=" mt-10 px-10 pt-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="font-secondary text-2xl"><span className="font-primary text-sm">03.</span>Zoning</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <img src={Zoning} alt="" className="flex items-center justify-center mx-auto py-10"/>
        </div>

        {/* Maquette */}
        <div className="mt-10 px-10 pt-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="font-secondary text-2xl"><span className="font-primary text-sm">04.</span>Maquette</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <img src={Maquette} alt="" className="flex items-center justify-center mx-auto py-10"/>
        </div>
    </div>
  )
}

export default SolutionCaisse