import React from 'react'

import Site from "../../assets/images/projects/Wordpress1/siteWordpress1.jpeg";
import Maquette from "../../assets/images/projects/Wordpress1/maquetteWordpress1.jpeg";

const Wordpress1 = () => {
  return (
    <div className="pt-36">
        {/* Présentation */}
        <div className="mt-10">
            <h3 className="font-secondary text-4xl">Faux Site</h3>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <div className="flex items-center mt-6">
                <div className="w-1/2">
                    <div className="flex flex-col md:flex-row items-left md:items-center justify-between">
                        <h4 className="font-secondary text-2xl">Categorie</h4>
                        <p className="mr-32">Site vitrine</p>
                    </div>
                    <div className="flex flex-col md:flex-row items-left md:items-center justify-between">
                        <h4 className="font-secondary text-2xl">Annee</h4>
                        <p className="mr-32">2024</p>
                    </div>
                </div>
                <div className="w-1/2">
                    <p>My Site a été réalisé pour m'exercer sur Wordpress.</p>
                </div>
            </div>
        </div>

        {/* Site */}
        <div className=" mt-10 px-10 pt-10 bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className="font-secondary text-2xl"><span className="font-primary text-sm">01.</span>Maquette Popularfx</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <img src={Maquette} alt="" className="flex items-center justify-center mx-auto py-10"/>
        </div>

        {/* Site Mobile */}
        <div className=" mt-10 px-10 pt-10  bg-white-secondary dark:bg-black-secondary shadow-lg rounded-lg">
            <h5 className=" font-secondary text-2xl"><span className="font-primary text-sm">02.</span>Site Web</h5>
            <div className="w-full h-0.5 bg-black-secondary dark:bg-white-primary"></div>
            <img src={Site} alt="" className="flex items-center justify-center mx-auto py-10"/>
        </div>
    </div>
  )
}

export default Wordpress1