import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm('service_p4bob3k', 'template_ez6ms8e', form.current, {
            publicKey: 'd_5RhIMKYArrU9DFF',
        })
        .then(
            (result) => {
                console.log('SUCCESS!');
                window.location.href = "/";
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
        );
    };
    return (
        <section id='contact' className="pt-20 md:pt-40 w-full h-full md:h-screen text-center px-4 md:px-0">
            <h2 className="text-2xl lg:text-4xl mb-10 text-center">-Me contacter-</h2>

            <form ref={form} onSubmit={sendEmail} action='' className='flex flex-col items-center bg-white-primary dark:bg-black-secondary md:mx-52 shadow-lg rounded-xl py-10 px-0 md:px-10'>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4 mb-4 w-72 md:w-full">
                    <input type='text' placeholder='Votre nom' required name="name" className="flex items-center justify-between py-2 px-4 rounded-full bg-gradient-to-t from-primary to-secondary mb-4 md:mb-0 dark:text-black-primary"/>
                    <input type="email" placeholder='Email' name="email" className="flex items-center justify-between py-2 px-4 rounded-full bg-gradient-to-t from-primary to-secondary dark:text-black-primary"/>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4 mb-4 w-72 md:w-full">
                    <input type="text" placeholder='Votre téléphone' required name='phone' className="flex items-center justify-between py-2 px-4 rounded-full bg-gradient-to-t from-primary to-secondary mb-4 md:mb-0 dark:text-black-primary"/>   
                    <input type='text' placeholder='Votre site web' name='web' className="flex items-center justify-between py-2 px-4 rounded-full bg-gradient-to-t from-primary to-secondary dark:text-black-primary"/>
                </div>

                    
                <textarea name="message" placeholder='Une description de votre besoin ou projet' className="flex items-center justify-between w-72 md:w-full px-4 text-left mb-10 pb-20 pt-4 rounded-xl bg-gradient-to-t from-primary to-secondary dark:text-black-primary"></textarea>

                <input type='submit' value="Envoyer"  className="border-2 border-black-secondary rounded-full py-1 px-4 hover:bg-black-primary hover:text-white-primary hover:border-black-primary dark:border-white-primary dark:hover:bg-white-primary dark:hover:text-black-primary"/>
                    
            </form>
        </section>
    )
}

export default Contact