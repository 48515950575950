import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Home from './components/Home'
import Skills from './components/Skills'
import Projects from './components/Projects'
import Contact from './components/Contact'
import CahierDesCharge from './components/CahierDesCharge'

const HomePage = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({smooth: true})
      }
    } else {
      document.documentElement.scrollTo(0, 0); // ou document.body.scrollTo(0, 0);
    }
  }, [location,]);

  return (
    <div>
      <Home  />
      <Skills />
      <Projects />
      <Contact />
      <CahierDesCharge/>
    </div>
  )
}

export default HomePage