import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Navbar from "./Navbar";
import Home from "./components/Home";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import HomePage from "./HomePage";
import Footer from "./Footer";

import SolutionCaisse from "./components/projects/SolutionCaisse";
import GarageFerrand from "./components/projects/GarageFerrand";
import Ekod1 from "./components/projects/Ekod1";
import Wordpress1 from "./components/projects/Wordpress1";
import MentionsLegales from "./MentionsLegales";
import Ekod2 from "./components/projects/Ekod2";


function App() {
  return (
    <div className="bg-white-primary dark:bg-black-primary text-black-secondary dark:text-white-primary">
      <Router>
      <Navbar/>
        <div className="max-w-6xl px-4 m-auto">
        
          <Routes>
            
            <Route path="/" element={<HomePage/>} />
            <Route path="/" element={<Home/>}  />
            <Route path="/competences" element={<Skills/>} />
            <Route path="/projets" element={<Projects/>} />
            <Route path="/contact" element={<Contact/>}  />

            <Route path="/solutionCaisse" element={<SolutionCaisse/>} />
            <Route path="/garageFerrand" element={<GarageFerrand/>} />
            <Route path="/ekod1" element={<Ekod1/>} />
            <Route path="/wordpress1" element={<Wordpress1/>} />
            <Route path="/ekod2" element={<Ekod2/>} />

            <Route path="/mentionsLegales" element={<MentionsLegales/>} />
          </Routes>
        </div>
      <Footer/>

      </Router>
    </div>
  );
}

export default App;