import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { LiaLinkedin } from "react-icons/lia";
import { TbMail } from "react-icons/tb";

const Footer = () => {
    return (
        <div className="flex flex-col items-center mt-20 pt-10 bg-white-secondary  dark:bg-black-secondary">
            <div className="border-2 mb-10 border-black-secondary rounded-full p-4 cursor-pointer hover:bg-black-secondary hover:text-white-primary dark:border-white-primary dark:hover:bg-white-primary dark:hover:text-black-primary">
                <span className="text-4xl font-secondary">EF</span>
            </div>

            <ul className="mb-10 flex flex-col md:flex-row items-center justify-center">
                <li>
                    <Link to="/" className="menu">Accueil</Link>
                </li>
                <li>
                    <Link to="/#competences" className="menu">Mes compétences</Link>
                </li>
                <li>
                    <Link to="/#projets" className="menu">Mes projets</Link>
                </li>
                <li>
                    <Link to="/#contact" className="menu">Me contacter</Link>
                </li>
            </ul>

            <div className="flex justify-center gap-4 mb-10">
                <a href='https://www.instagram.com/frnd.eva/' target='_blank'><FaInstagram className="text-4xl cursor-pointer"/></a>
                <a href='https://www.linkedin.com/in/eva-ferrand-2694b51b7/' target='_blank'><LiaLinkedin className="text-4xl cursor-pointer"/></a>
                <a href='mailto:contact@evaferrand.fr'><TbMail className="text-4xl cursor-pointer"/></a>
            </div>

            <div className="mb-4 flex flex-col items-center justify-center">
                <Link to="/mentionsLegales" className="pb-4">Mentions légales</Link>
                <p className="px-10">Copyright © 2024 | Eva Ferrand</p>
            </div>
        </div>
    )
}

export default Footer