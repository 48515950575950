import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from 'react-icons/fa';
import { FiSun } from "react-icons/fi";
import { IoMoonOutline } from "react-icons/io5";

function Navbar() {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("isDarkMode") === "true" ? true : false
  );
  const [isScrolling, setIsScrolling] = useState(false);

  function toggleTheme() {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode); 
    localStorage.setItem("isDarkMode", newMode);
    document.documentElement.classList.toggle('dark');
  }

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Set the dark mode when component mounts
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div className={`fixed z-50 w-full h-24 px-4 md:px-20 bg-white-primary dark:bg-black-primary flex justify-between items-center py-5 ${isScrolling ? 'shadow-md bg-white-secondary dark:bg-black-secondary' : ''}`}>
      <a href="/" className='z-10'>
        <div className="border-2 border-black-secondary rounded-full p-4 cursor-pointer hover:bg-black-secondary hover:text-white-primary dark:border-white-primary dark:hover:bg-white-primary dark:hover:border-white-primary dark:hover:text-black-primary">
          <span className="text-4xl font-secondary">EF</span>
        </div>
      </a>

      {/* Menu */}
      <ul className='md:flex hidden items-center justify-between text-[14px]'>
        <li className="menu">
          <Link to="/">Accueil</Link>
        </li>
        <li className="menu">
          <Link to="/#competences">Mes compétences</Link>
        </li> 
        <li className="menu">
          <Link to="/#projets" offset={20}>Mes projets</Link>
        </li>
        <li className="menu">
          <Link to="/#contact" offset={60}>Me contacter</Link>
        </li>
      </ul>

      <div onClick={toggleTheme} className="hidden md:block flex items-center justify-between gap-4 border-2 border-black-secondary dark:border-white-primary rounded-full">
        <button className={`${isDarkMode ? '' : 'bg-gradient-to-b from-secondary to-primary rounded-full'} p-2`}>
          <FiSun className="text-3xl" />
        </button>

        <button className={`${isDarkMode ? 'bg-gradient-to-b from-secondary to-primary rounded-full' : '' } p-2`}>
          <IoMoonOutline  className="text-3xl" />
        </button>
      </div>
  
      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10 flex items-center gap-10'>
        {!nav ? <FaBars className='text-2xl'/> : <FaTimes className='text-2xl'/>}
      </div>

      {/* Mobile Menus */}
      <ul className={!nav ? 'hidden' : 'absolute z-0 top-0 left-0 w-full h-screen bg-primary dark:bg-black-primary flex flex-col justify-center items-center'}>
        <div onClick={toggleTheme} className="my-6 flex items-center justify-between gap-4 border-2 border-black-secondary rounded-full">
          <button  className={`${isDarkMode ? '' : 'bg-white-primary rounded-full'} p-2`}>
            <FiSun className="text-3xl " />
          </button>

          <button className={`${isDarkMode ? 'bg-white-primary rounded-full dark:text-black-primary' : '' } p-2`}>
            <IoMoonOutline  className="text-3xl" />
          </button>
        </div>
            
        <li className='py-6 text-2xl font-semibold'>
          <Link onClick={handleClick} to="/">
            Accueil
          </Link>
        </li>
        <li className='py-6 text-2xl font-semibold'>
          <Link onClick={handleClick} to="/#competences">
            Mes compétences
          </Link>
        </li>
        <li className='py-6 text-2xl font-semibold'>
          <Link onClick={handleClick} to="/#projets">
            Mes projets
          </Link>
        </li>
        <li className='py-6 text-2xl font-semibold'>
          <Link onClick={handleClick} to="/#contact">
            Me contacter
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
